import colors from '@arcadehq/configs/tailwind/colors'
import { cn } from '@arcadehq/shared/helpers'
import { DistributiveOmit } from '@arcadehq/shared/lib/types'
import React, { ReactNode } from 'react'
import { fixedForwardRef } from 'src/helpers'
import { testID } from 'src/utils/tests'

import { LoaderType } from './loaders/type'
import Spinner from './Spinner'

export type IButtonProps<TAs extends React.ElementType> = {
  as?: TAs
  buttonType?: ButtonTypes
  buttonSize?: ButtonSizes
  className?: string
  loading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  iconOnly?: ReactNode
} & DistributiveOmit<
  // we need to remove the `as` prop because there are some HTML elements that support it
  // and 'key' started causing troubles only in React 18
  React.ComponentPropsWithRef<React.ElementType extends TAs ? 'button' : TAs>,
  'as' | 'key'
>

const Btn = <TAs extends React.ElementType>(
  props: IButtonProps<TAs>,
  ref: React.ForwardedRef<any>
) => {
  const {
    as: Comp = 'button',
    children,
    className,
    disabled,
    buttonType = ButtonTypes.primary,
    buttonSize = ButtonSizes.md,
    loading = false,
    leftIcon,
    rightIcon,
    iconOnly,
    onClick,
    ...rest
  } = props

  const btnType = disabled ? ButtonTypes.neutral : buttonType

  const iconGap = cn({
    'pr-[6px]': buttonSize === ButtonSizes.sm,
    'pr-[8px]': buttonSize === ButtonSizes.md || buttonSize === ButtonSizes.lg,
    'pr-[10px]': buttonSize === ButtonSizes.xl,
  })

  return (
    <Comp
      {...testID('Button')}
      className={cn(
        'font-semibold relative cursor-pointer',
        'rounded-lg',
        'transition-colors no-animation focus:outline-none',
        {
          'bg-primary-action border border-primary-border text-white shadow-btn hover:bg-primary-action-hover hover:border-primary-action active:bg-primary-action active:border-primary-border active:shadow-none':
            btnType === ButtonTypes.primary,
          'bg-white border border-gray-200 text-gray-900 hover:bg-gray-50 hover:border-gray-200 active:bg-gray-100 active:border-gray-200':
            btnType === ButtonTypes.secondary,
          'bg-gray-100 border border-gray-100 text-gray-900 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 active:border-gray-300':
            btnType === ButtonTypes.base ||
            btnType === ButtonTypes.light ||
            btnType === ButtonTypes.neutral,
          'bg-transparent border border-transparent text-gray-900 hover:bg-gray-100 hover:border-gray-100 active:bg-gray-200 active:border-gray-200':
            btnType === ButtonTypes.ghost,
          'bg-transparent text-gray-900 hover:bg-transparent hover:text-gray-900 link px-0':
            btnType === ButtonTypes.text,
          'bg-critical-action border border-critical-border text-white shadow-btn hover:bg-critical-action-hover hover:border-critical-action active:bg-critical-action active:border-critical-border active:shadow-none':
            btnType === ButtonTypes.danger,
          'bg-gray-100 border border-gray-100 cursor-not-allowed shadow-none hover:bg-gray-100 hover:border-gray-100 active:bg-gray-100 active:border-gray-100 text-gray-500':
            disabled,
          'h-8 px-2 text-xs [&_svg]:h-4 [&_svg]:w-4':
            buttonSize === ButtonSizes.sm,
          'h-9 px-4 text-sm [&_svg]:h-[18px] [&_svg]:w-[18px]':
            buttonSize === ButtonSizes.md,
          'h-12 px-4 text-base [&_svg]:h-6 [&_svg]:w-6':
            buttonSize === ButtonSizes.lg,
          'h-16 px-6 text-lg [&_svg]:h-6 [&_svg]:w-6':
            buttonSize === ButtonSizes.xl,
          'w-8 p-[8px]': iconOnly && buttonSize === ButtonSizes.sm,
          'w-9 p-[9px]': iconOnly && buttonSize === ButtonSizes.md,
          'w-12 p-[12px]': iconOnly && buttonSize === ButtonSizes.lg,
          'w-16 p-[20px]': iconOnly && buttonSize === ButtonSizes.xl,
        },
        className
      )}
      {...rest}
      onClick={onClick && !loading ? onClick : undefined}
      ref={ref}
    >
      {iconOnly ? (
        <div className='flex items-center justify-center absolute inset-0'>
          {iconOnly}
        </div>
      ) : (
        <div className='flex items-center justify-center w-full h-full'>
          {leftIcon}
          {leftIcon && children && <div className={iconGap} />}
          {loading ? (
            <Spinner
              color={
                buttonType === ButtonTypes.primary
                  ? colors.white
                  : colors.gray[900]
              }
              secondaryColor={colors.gray[200]}
              size={20}
              type={LoaderType.Oval}
            />
          ) : typeof children === 'string' ? (
            <span>{children}</span>
          ) : (
            children
          )}
          {rightIcon && children && <div className={iconGap} />}
          {rightIcon}
        </div>
      )}
    </Comp>
  )
}

export const Button = fixedForwardRef(Btn)

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'neutral',
  ghost = 'ghost',
  danger = 'danger',
  text = 'text', // Deprecated... use ghost instead (might re-use it if ghost become an issue with padding)
  base = 'base', // Deprecated... use neutral instead
  light = 'light', // Deprecated... use neutral instead
}

export enum ButtonSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}
