import ArcadeError from 'src/components/ArcadeError'

export default function Custom404() {
  return (
    <ArcadeError
      statusCode={404}
      title='Oops! We dropped the ball.'
      subTitle={
        <>
          Something went wrong here. We recommend trying this action again.
          <br />
          If you&apos;ve tried multiple times, please reach out to us at{' '}
          <a className='link' href='mailto:support@arcade.software'>
            support@arcade.software
          </a>
          .
        </>
      }
    />
  )
}
