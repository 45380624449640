// more types available here:

import { Oval, Puff } from './loaders'
import { LoaderType } from './loaders/type'

export interface Props {
  className?: string
  color?: string
  secondaryColor?: string
  size?: number
  type?: LoaderType
}

export default function Spinner({
  className,
  color = '#4F46E5',
  secondaryColor,
  size = 50,
  type = LoaderType.Puff,
}: Props) {
  return (
    <div className={`flex items-center justify-center ${className ?? ''}`}>
      {type === 'Puff' && <Puff color={color} height={size} width={size} />}
      {type === 'Oval' && (
        <Oval
          color={color}
          secondaryColor={secondaryColor}
          height={size}
          width={size}
          strokeWidth={6}
          strokeWidthSecondary={6}
        />
      )}
    </div>
  )
}
